.timeline {
  margin: 2% 10% 2% 10%;
}

.title {
  color: white;
  margin-top: 2%;
}

.date-text {
  color: white;
}
